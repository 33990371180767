import $axios from "@/service/service.util.js";

// 获取设备列表
const getDecieList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/list',
    method: 'post',
    data
  })
}
//保存课程
const modifyEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/updateEnterpriseId',
    method: 'post',
    data
  })
}
//获取所有水司
const getAllEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/enterprise/allList',
    method: 'post',
    data
  })
}
//更新设备所属水司
const updateEnpr = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/updateEnterpriseId',
    method: 'post',
    data
  })
}
// 删除设备
const delDevice = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/device/logicDelete',
    method: 'post',
    data
  })
}
//下发阀控指令
const releaseOrder = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/cmDevice/releaseOrder',
    method: 'post',
    data
  })
}

//注册设备
const registDevice = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/iot/cmDevice/createDevice',
    method: 'post',
    data
  })
}
export default {
  getDecieList,
  modifyEnpr,
  getAllEnpr,
  updateEnpr,
  delDevice,
  releaseOrder,
  registDevice
}
