<template>
<div ref="editor" style="text-align:left"></div>
</template>

<script>
// import E from 'wangeditor'
export default {
    name: "GlobalEditor",
    props: {
        editorContent: Object
    },
    data() {
        return {
            editor: null
        }
    },
    methods: {
        // 编辑器回显文字
        editorText(html) {
            this.editor.txt.html(html);
        }
    },
    mounted() {
        this.$nextTick(_ => {
            this.editor = new E(this.$refs.editor);
            this.editor.customConfig.uploadImgShowBase64 = false // base 64 存储图片
            this.editor.customConfig.uploadImgServer = process.env.VUE_APP_BASE_API + '/api/file/upload' // 配置服务器端地址
            this.editor.customConfig.uploadImgHeaders = {
                JWTToken: sessionStorage.getItem('token'),
                ctype: 'PC'
            } // 自定义 header
            this.editor.customConfig.uploadFileName = 'file' // 后端接受上传文件的参数名
            this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
            this.editor.customConfig.uploadImgMaxLength = 1 // 限制一次最多上传 1 张图片
            this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间

            // 配置菜单
            this.editor.customConfig.menus = [
                'head', // 标题
                'bold', // 粗体
                'fontSize', // 字号
                'fontName', // 字体
                'italic', // 斜体
                'underline', // 下划线
                'strikeThrough', // 删除线
                'foreColor', // 文字颜色
                'backColor', // 背景颜色
                'link', // 插入链接
                'list', // 列表
                'justify', // 对齐方式
                'quote', // 引用
                'emoticon', // 表情
                'image', // 插入图片
                'table', // 表格
                // 'video', // 插入视频
                'code', // 插入代码
                'undo', // 撤销
                'redo', // 重复
                'fullscreen' // 全屏
            ]

            this.editor.customConfig.uploadImgHooks = {
                fail: (xhr, editor, result) => {
                    // 插入图片失败回调
                },
                success: (xhr, editor, result) => {
                    // 图片上传成功回调
                },
                timeout: (xhr, editor) => {
                    // 网络超时的回调
                },
                error: (xhr, editor) => {
                    // 图片上传错误的回调
                },
                customInsert: (insertImg, result, editor) => {
                    console.log(result)
                    // 图片上传成功，插入图片的回调
                    //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
                    // console.log(result.data[0].url)
                    //insertImg()为插入图片的函数
                    //循环插入图片
                    // for (let i = 0; i < 1; i++) {
                    // console.log(result)
                    let url = result.data
                    insertImg(url)
                    // }
                }
            }
            this.editor.customConfig.onchange = (html) => {
                console.log(html)
                this.editorContent.info = html;
            }
            this.editor.create();
        })
    }
}
</script>

<style>
.w-e-text-container {
    z-index: 99 !important;
}

.w-e-toolbar .w-e-menu {
    z-index: 999 !important;
}
</style>
