<template>
    <el-upload :action='action' :on-success="success" :on-error="uploadError" :before-upload="beforeUpload" :file-list="fileList" :headers="headers">
        <el-button class="set-enclosure" size="small" :icon="icon" :type="type">
            <slot name="ButtonName"></slot>
        </el-button>
    </el-upload>
</template>

<script>
export default {
    name: 'GlobalUpload',
    props: {
        type: {
            type: String,
            default: 'text'
        },
        icon: {
            type: String,
            default: ''
        },
        action: {
            type: String,
            default: process.env.VUE_APP_Agreement + process.env.VUE_APP_DomainName + process.env.VUE_APP_Version + process.env.VUE_APP_User + '/uploadHandler/upload'
        }
    },
    data () {
        return {
            fileList: [], // 文件列表
            headers: {
                "routeType": 1,
                "token": ""
            }
        }
    },
    methods: {
        // 附件上传成功
        success(response, file){
            this.fileList = [];
            if (response.data && response.data.length != 0){
                if (response.data[0].fileId){
                    this.$emit('fileSuccess', response);
                } else {
                    this.$message({ type: 'error', message: '文件不可为空，请重新上传！' });
                }
            }
        },
        // 附件上传失败
        uploadError(err, file){
            console.log(err);
            this.$message({ type: 'error', message: '上传失败，请稍后重新上传！' });
        },
        // 上传之前钩子函数
        beforeUpload(file){
            const isSize = file.size / 1024 / 1024 < process.env.VUE_APP_UploadSize;
            if (!isSize){
                this.$message.error('上传文件大小不能超过 50MB!');
            }
            return isSize;
        }
    },
    computed: {
        token() {
            let token = this.$Fn.GetCookie('token');
            return token;
        }
    },
    mounted(){
        this.$nextTick(_ => {
            this.headers.token = this.token;
        })
    }
}
</script>

<style>
    .set-enclosure {
        font-size: 14px;
        padding: 0;
    }
</style>
