import axios from "axios";
import qs from "qs"
import { Message } from "element-ui"
import router from '../router/index'


const $axios = (options) => {
  return new Promise((resolve, reject) => {
    // 默认配置 axios 实例
    const instance = axios.create({
      // baseURL: process.env.VUE_APP_Agreement + process.env.VUE_APP_DomainName,// 默认请求 ip
      headers: {// 默认 json 请求
        'Content-Type': 'application/json;charset=UTF-8'
      },
      timeout: process.env.VUE_APP_Timeout,// 请求时间
      paramsSerializer(params) { // 序列化函数
        qs.stringify(params);
      }
    });
    // let userId = $Fn.GetCookie('userId');
    let token = sessionStorage.getItem('token');
    // 请求配置
    instance.interceptors.request.use(config => {
      // 请求开始的时候可以修改默认配置选项
      // console.log('请求前拦截', config);
      config.headers['myAuthorization'] = token;
      return config;
    }, error => {
      return Promise.reject(error);
    })
    // 响应配置
    instance.interceptors.response.use(response => {
      let resData;
      if (response.data == undefined) {
        resData = JSON.parse(response.request.responseText);
      } else {
        resData = response.data;
      }
      if (resData.code === 1 && resData.msg == 'TOKEN已失效'){
        sessionStorage.clear()
        Message({
          message: '登录已过期，请重新登录',
          type: 'warning'
        });
        router.replace({
          path: '/login'
        });
      }
      return resData;
    }, error => {
      if (error && error.response) {
        switch (error.response.status) {
          case 400:
            error.response.data.message = '错误请求'
            break;
          case 401:
            error.response.data.message = '未授权，请重新登录';
            router.replace({
              path: '/login',
              query: { redirect: router.currentRoute.fullPath }
            });
            break;
          case 403:
            error.response.data.message = '拒绝访问'
            break;
          case 404:
            error.response.data.message = '请求错误,未找到该资源'
            break;
          case 405:
            error.response.data.message = '请求方法未允许'
            break;
          case 408:
            error.response.data.message = '请求超时'
            break;
          // case 500:
          //     error.response.data.message = '服务器端出错'
          //     break;
          case 501:
            error.response.data.message = '网络未实现'
            break;
          case 502:
            error.response.data.message = '网络错误'
            break;
          case 503:
            error.response.data.message = '服务不可用'
            break;
          case 504:
            error.response.data.message = '网络超时'
            break;
          case 505:
            error.response.data.message = 'http版本不支持该请求'
            break;
          // default:
          //     error.response.data.message = error.response.data.message
        }
      }
      console.log(error)
      if (error.response.data.message || error.message) {
        // 错误提示弹框
        Message({
          showClose: true,
          message: error.response.data.message || error.message,
          type: "error"
        })
      }
      return Promise.reject(error.response);
    })
    // 请求处理
    instance(options).then((res) => {
      resolve(res);
      return false;
    }).catch((error) => {
      reject(error);
    })
  })
}

export default $axios;
