<template>
    <el-button class="downBtn" size="small" :type="type" :icon="icon" @click.stop="download(data)">
        <slot name="ButtonName"></slot>
    </el-button>
</template>

<script>
export default {
    name: 'GlobalDownload',
    props: {
        icon: {// icon 展示
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        data: [Object, Array]
    },
    methods: {
        // 下载
        download(data){
            let reqData = {
                type: null,
                fileId: []
            }
            if (data instanceof Array){ // 多文件
                reqData.type = 2;
                reqData.fileId = data;
            } else { // 单文件
                reqData.type = 1;// 1：单文件下载, 2：多文件下载
                reqData.fileId.push(data.fileId);
            }
            this.$api.attachmentDownload(reqData).then((res) => {
                if (data instanceof Array){
                    let time = this.$Fn.FormatDate(new Date(), 'yyyy-MM-dd');
                    this.streamDownload(res, `${time}.zip`);
                } else {
                    this.streamDownload(res, data.fileName);
                }
            })
        },
        // 流媒体下载
        streamDownload(stream, fileName){
            if (stream && stream.byteLength != 0){
                if (window.navigator.msSaveOrOpenBlob){
                    navigator.msSaveOrOpenBlob(new Blob([stream]), `${fileName}`);
                } else {
                    let blob = new Blob([stream]);
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = `${fileName}`;
                    a.click();
                }
                this.$emit('downloadSuccess');
            } else {
                this.$message({ type: 'error', message: '下载失败，请稍后重试！' });
                this.$emit('downloadError');
            }
        }
    }
}
</script>

<style>
    .downBtn{
        padding: 0;
        font-size: 14px;
    }
</style>
