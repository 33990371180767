import Vue from 'vue'
import Router from 'vue-router'
import Layout from "@/views/Layout/Layout"// 公共视图层
import watermark from '../utils/watermark'
Vue.use(Router)

/**
 * hidden: true  如果 'hidden' 为 true 则不会在边栏中显示（默认值为false）
 * alwaysShow: true 如果为true 表示显示一级展示
 *
 * meta: {
 *     title: '首页'     子菜单和面包屑中显示的名称
 *     icon: 'el-xxx'    图标字
 * }
 */

export const constantRouterMap = [
    {
        path: '/login',
        hidden: true,
        name: 'signIn',
        meta: {
            status: 'login',
            id: '1'
        },
        component: () => import('@/views/Login/SignIn.vue')
    },
    {
        path: '/',
        name: 'dagl',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/index',
        meta: {
            title: '档案管理',
            icon: require('@/assets/icon/menu/meter.png'),
            code: 'dagl',
            id: '0001'
        },
        children: [
            {
                path: 'index',
                name: 'index',
                hidden: true,
                component: () => import('@/views/DAGL/daIndex.vue'),
                meta: {
                    title: '用户档案',
                    code: 'sbgl1',
                    id: '00011'
                }
            }
        ]
    },
    {
        path: '/jfxm',
        name: 'jfxm',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/jfxm/jgxm',
        meta: {
            title: '计费项目',
            icon: require('@/assets/icon/menu/xl.png'),
            code: 'bm',
            id: '0012'
        },
        children: [
            {
                path: 'jgxm',
                name: 'jgxm',
                hidden: true,
                component: () => import('@/views/JFXM/jgxm/jgxmIndex.vue'),
                meta: {
                    title: '价格项目',
                    code: 'sblxlist',
                    id: '00121'
                }
            },
            {
                path: 'jgmb',
                name: 'jgmb',
                hidden: true,
                component: () => import('@/views/JFXM/jgmb/jgmbIndex.vue'),
                meta: {
                    title: '价格模板',
                    code: 'sblxlist',
                    id: '00121'
                }
            },
            {
                path: 'znjmb',
                name: 'znjmb',
                hidden: true,
                component: () => import('@/views/JFXM/znjmb/znjmbIndex.vue'),
                meta: {
                    title: '滞纳金模板',
                    code: 'sblxlist',
                    id: '00121'
                }
            }
        ]
    },
    {
        path: '/sfgl',
        name: 'sfgl',
        hidden: true,
        component: Layout,
        alwaysShow: true,
        redirect: '/sfgl/chongzhi',
        meta: {
            title: '收费管理',
            icon: require('@/assets/icon/menu/company.png'),
            code: 'ssgl',
            id: '0002'
        },
        children: [
            {
                path: 'chongzhi',
                name: 'chongzhi',
                hidden: true,
                component: () => import('@/views/SFGL/chongzhi/czIndex.vue'),
                meta: {
                    title: '充值',
                    code: 'ssgl1',
                    id: '00021'
                }
            },
            {
                path: 'czjl',
                name: 'czjl',
                hidden: true,
                component: () => import('@/views/SFGL/czjl/czjlIndex.vue'),
                meta: {
                    title: '充值记录',
                    code: 'ssgl1',
                    id: '00021'
                }
            },
            {
                path: 'zdjf',
                name: 'zdjf',
                hidden: true,
                component: () => import('@/views/SFGL/zdjf/zdjfIndex.vue'),
                meta: {
                    title: '账单缴费',
                    code: 'ssgl1',
                    id: '00021'
                }
            },
            {
                path: 'jfjl',
                name: 'jfjl',
                hidden: true,
                component: () => import('@/views/SFGL/jfjl/jfjlIndex.vue'),
                meta: {
                    title: '缴费记录',
                    code: 'ssgl1',
                    id: '00021'
                }
            }
        ]
    },
    // {
    //     path: '/ywbl',
    //     name: 'ywbl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/ywbl/zhyw',
    //     meta: {
    //         title: '业务办理',
    //         icon: require('@/assets/icon/menu/api.png'),
    //         code: 'jkgl',
    //         id: '0003'
    //     },
    //     children: [
    //         {
    //             path: 'zhyw',
    //             name: 'zhyw',
    //             hidden: true,
    //             component: () => import('@/views/YWBL/zhyw/zhywIndex.vue'),
    //             meta: {
    //                 title: '综合业务',
    //                 code: 'ssjk1',
    //                 id: '00031'
    //             }
    //         },
    //         {
    //             path: 'ywjf',
    //             name: 'ywjf',
    //             hidden: true,
    //             component: () => import('@/views/YWBL/ywjf/ywjfIndex.vue'),
    //             meta: {
    //                 title: '业务缴费',
    //                 code: 'ssjk1',
    //                 id: '00031'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/sjcx',
    //     name: 'sjcx',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/sjcx/zhcx',
    //     meta: {
    //         title: '数据查询',
    //         icon: require('@/assets/icon/menu/account.png'),
    //         code: 'hygl',
    //         id: '0004'
    //     },
    //     children: [
    //         {
    //             path: 'zhcx',
    //             name: 'zhcx',
    //             hidden: true,
    //             component: () => import('@/views/SJCX/sjcxIndex.vue'),
    //             meta: {
    //                 title: '综合查询',
    //                 code: 'hyxl',
    //                 id: '00041'
    //             }
    //         },
    //         {
    //             path: 'zhye',
    //             name: 'zhye',
    //             hidden: true,
    //             component: () => import('@/views/SJCX/sjcxIndex.vue'),
    //             meta: {
    //                 title: '账户余额',
    //                 code: 'hyxl',
    //                 id: '00041'
    //             }
    //         },
    //         {
    //             path: 'drwcz',
    //             name: 'drwcz',
    //             hidden: true,
    //             component: () => import('@/views/SJCX/sjcxIndex.vue'),
    //             meta: {
    //                 title: '多日未充值',
    //                 code: 'hyxl',
    //                 id: '00041'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/xlgl',
    //     name: 'xlgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/xlgl/xilieguanli',
    //     meta: {
    //         title: '日志管理',
    //         icon: require('@/assets/icon/menu/log.png'),
    //         code: 'xlgl',
    //         id: '0005'
    //     },
    //     children: [
    //         // {
    //         //     path: 'xilieguanli',
    //         //     name: 'xilieguanli',
    //         //     hidden: true,
    //         //     component: () => import('@/views/XLGL/xlIndex.vue'),
    //         //     meta: {
    //         //         title: '登录管理',
    //         //         code: 'xlgl1',
    //         //         id: '00051'
    //         //     }
    //         // },
    //         {
    //             path: 'xilieguanli1',
    //             name: 'xilieguanli1',
    //             hidden: true,
    //             component: () => import('@/views/XLGL/xlIndex.vue'),
    //             meta: {
    //                 title: '指令日志',
    //                 code: 'xlgl1',
    //                 id: '00051'
    //             }
    //         }
    //     ]
    // },
    // ,
    // {
    //     path: '/zbgl',
    //     name: 'zbgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/zbgl/zhiboguanli',
    //     meta: {
    //         title: '直播管理',
    //         icon: require('@/assets/icon/menu/zb.png'),
    //         code: 'zbgl',
    //         id: '0006'
    //     },
    //     children: [
    //         {
    //             path: 'zhiboguanli',
    //             name: 'zhiboguanli',
    //             hidden: true,
    //             component: () => import('@/views/ZBGL/zbIndex.vue'),
    //             meta: {
    //                 title: '直播管理',
    //                 code: 'zbgl1',
    //                 id: '00061'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/kzgl',
    //     name: 'kzgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/kzgl/kaozhengguanli',
    //     meta: {
    //         title: '考证管理',
    //         icon: require('@/assets/icon/menu/kz.png'),
    //         code: 'kzgl',
    //         id: '0007'
    //     },
    //     children: [
    //         {
    //             path: 'kaozhengguanli',
    //             name: 'kaozhengguanli',
    //             hidden: true,
    //             component: () => import('@/views/KZGL/kzIndex.vue'),
    //             meta: {
    //                 title: '考证管理',
    //                 code: 'kzgl1',
    //                 id: '00071'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/tjfx',
    //     name: 'tjfx',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/tjfx/xstj',
    //     meta: {
    //         title: '统计分析',
    //         icon: require('@/assets/icon/menu/tj.png'),
    //         code: 'tjfx',
    //         id: '0008'
    //     },
    //     children: [
    //         {
    //             path: 'xstj',
    //             name: 'xiaoshoutongji',
    //             hidden: true,
    //             component: () => import('@/views/TJFX/components/xstjList.vue'),
    //             meta: {
    //                 title: '销售统计',
    //                 code: 'xstj',
    //                 id: '00081'
    //             }
    //         },
    //         {
    //             path: 'bftj',
    //             name: 'bofangtongji',
    //             hidden: true,
    //             component: () => import('@/views/TJFX/components/bftjList.vue'),
    //             meta: {
    //                 title: '播放统计',
    //                 code: 'bftj',
    //                 id: '00082'
    //             }
    //         },
    //         {
    //             path: 'yhtj',
    //             name: 'yonghutongji',
    //             hidden: true,
    //             component: () => import('@/views/TJFX/components/yhtjList.vue'),
    //             meta: {
    //                 title: '用户统计',
    //                 code: 'yhtj',
    //                 id: '00083'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/huiyigl',
    //     name: 'huiyigl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/huiyigl/huiyiguanli',
    //     meta: {
    //         title: '会议管理',
    //         icon: require('@/assets/icon/menu/meeting.png'),
    //         code: 'huiyigl',
    //         id: '0009'
    //     },
    //     children: [
    //         {
    //             path: 'huiyiguanli',
    //             name: 'huiyiguanli',
    //             hidden: true,
    //             component: () => import('@/views/HUIYI/huiyiIndex.vue'),
    //             meta: {
    //                 title: '会议列表',
    //                 code: 'yuiyilb',
    //                 id: '00091'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/jsgl',
    //     name: 'jsgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/jsgl/jueseguanli',
    //     meta: {
    //         title: '角色管理',
    //         icon: require('@/assets/icon/menu/role.png'),
    //         code: 'jsgl',
    //         id: '0010'
    //     },
    //     children: [
    //         {
    //             path: 'jueseguanli',
    //             name: 'jueseguanli',
    //             hidden: true,
    //             component: () => import('@/views/JSGL/jsIndex.vue'),
    //             meta: {
    //                 title: '角色列表',
    //                 code: 'jslb',
    //                 id: '00101'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/yhgl',
    //     name: 'yhgl',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/yhgl/yonghuguanli',
    //     meta: {
    //         title: '用户管理',
    //         icon: require('@/assets/icon/menu/user.png'),
    //         code: 'yhgl',
    //         id: '0011'
    //     },
    //     children: [
    //         {
    //             path: 'yonghuguanli',
    //             name: 'yonghuguanli',
    //             hidden: true,
    //             component: () => import('@/views/YHGL/yhIndex.vue'),
    //             meta: {
    //                 title: '用户列表',
    //                 code: 'yhlb',
    //                 id: '00111'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/bm',
    //     name: 'bm',
    //     hidden: true,
    //     component: Layout,
    //     alwaysShow: true,
    //     redirect: '/bm/bannermanage',
    //     meta: {
    //         title: 'Banner管理',
    //         icon: require('@/assets/icon/menu/banner.png'),
    //         code: 'bm',
    //         id: '0012'
    //     },
    //     children: [
    //         {
    //             path: 'bannermanage',
    //             name: 'bannermanage',
    //             hidden: true,
    //             component: () => import('@/views/BannerManage/bannerIndex.vue'),
    //             meta: {
    //                 title: 'Banner列表',
    //                 code: 'bannerlist',
    //                 id: '00121'
    //             }
    //         }
    //     ]
    // }
    { path: '*', redirect: '/', meta: { id : '0'} }
]
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRouterMap
})
// 全局拦截
router.beforeEach((to, from, next) => {
    console.log(to)
    const menuPower = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')).menuCode : []
    if (sessionStorage.getItem('token') && to.fullPath !== '/login') {
        // if (to.matched.length > 0) {
        //     if (menuPower.every(n => n !== to.matched[0].meta.id)) {
        //         let fullPath = constantRouterMap.filter(item => item.meta.id === menuPower[0])[0].path
        //         next(fullPath)
        //     } else {
        //         next();
        //     }
        // } else {
        //     next();
        // }
        next();
    } else {
        if (to.fullPath == '/login') {
            next();
        } else {
            next('/login');
        }
    }
})
const initWaterMark = () => {
    console.log(watermark)
    watermark.init({
        watermark_txt: `珠海鼎通科技有限公司`,
        watermark_x: -60,
        watermark_y: 0,
        watermark_x_space: 240,
        watermark_y_space: 240,
        watermark_color: '#2898ff',
        watermark_width: 178,
        watermark_angle: 325,
        watermark_alpha: 0.2,
        watermark_fontsize: '16px'
    })
}
router.afterEach((to, from) => {
    const noMarkPages = ['/login']
    if (!noMarkPages.includes(location.pathname)) {
        initWaterMark()
    }
})
export default router;
