<template>
<div class="app-navBar clearfix">
    <div class="logo fl" style="display: flex; align-items: center;">
        <!-- <i class="el-icon-s-fold" @click="handleLefeMenu"></i> -->
        <!-- <img class="open_close" @click="handleLefeMenu" :src="openclolseState? closeIcon: openIcon" alt=""> -->
        <img class="cbgoimg" :src="require('@/assets/icon/navbar/cbpt.png')" alt="">
        <a class="cbgo" href="http://8.129.106.36:8080/dist/#/">前往抄表平台</a>
    </div>
    <div class="user fr">
        <img class="user-img" src="@/assets/icon/common/header.png" />
        <span class="user-name">{{ username }}</span>
        <el-dropdown trigger="click" @command='setDialogInfo'>
            <span class="el-dropdown-link">
                <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command='logout'>退出</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</div>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            closeIcon: require('@/assets/icon/navbar/close.png'),
            openIcon:  require('@/assets/icon/navbar/open.png'),
            openclolseState: true
        }
    },
    mounted() {},
    methods: {
        setDialogInfo(cmditem) {
            if (!cmditem) {
                this.$message('菜单选项缺少command属性');
                return;
            }
            if (cmditem == 'logout') {
                sessionStorage.clear()
                this.$router.push({
                    path: '/login'
                })
            }
        },
        // 收缩左侧菜单
        handleLefeMenu() {
            this.$store.dispatch('setLeftCollapse'); // 折叠菜单
            this.$store.dispatch('handleLeftMenu'); // 改变菜单宽度
            this.openclolseState = !this.openclolseState
        }
    },
    computed: {
        username() {
            //  获取用户昵称
            let username = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')).userName : '后台用户'
            return username;
        }
    }
}
</script>

<style lang="scss">
.app-navBar .el-dialog__header {
    padding: 0 20px;
    line-height: 65px;
    border-bottom: 1px solid #e2edf5;
}

.app-navBar .inp-width {
    width: 280px;
}

.app-navBar .el-form-item__label {
    text-align: left;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #2d3039;
}

.app-navBar .el-form-item {
    margin-left: 8%;
}

.app-navBar .el-input__inner {
    border-radius: 18px;
}

.app-navBar {
    .el-dialog__header span {
        border: 0 !important;
        font-weight: 600;
    }

    .el-dialog__headerbtn {
        top: 23px;
    }

    .submitBtn {
        width: 180px;
        display: block;
        margin: 0 auto;
        border-radius: 24px;
        background: #1e61ce;
    }

    // position: fixed;
    width: 100%;

    .msgNote {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        margin: 0 0 0 22px;

        &.flash {
            animation: flickering 2.5s infinite forwards;
        }

        .msgImg {
            width: 20px;
        }

        .msgCount {
            position: absolute;
            top: 20px;
            left: 20px;
            font-size: 12px;
            padding: 2px 3px;
            color: #ffffff;
            background-color: #f56c6c;
            border-radius: 3px;
            line-height: 12px;
        }
    }

    .username {
        display: inline-block;
    }

    .el-dropdown-link {
        cursor: pointer;
    }

    @keyframes flickering {

        from,
        50%,
        to {
            opacity: 1;
        }

        25%,
        75% {
            opacity: 0;
        }
    }
}

@media only screen and (max-width: 1599px) {
    .app-navBar {
        .inp-width {
            width: 220px;
        }
    }
}
.cbgoimg{
    width:22px; 
    margin-right: 5px;
}
.cbgo{
    color:#fff;
    cursor: pointer;
}
</style>
