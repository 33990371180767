<template>
    <el-dialog class="attachment-dialog" title="附件预览" :visible.sync="propsData.dialogVisible" append-to-body top="12vh">
        <div class="content">
            <div v-if="imgShow">
                <img :src="src" alt="">
            </div>
            <div v-if="videoShow">
                <video-player class="vjs-custom-skin" ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    @play="onPlayerPlay($event)"
                    @pause="onPlayerPause($event)"
                    @ended="onPlayerEnded($event)"
                    @loadeddata="onPlayerLoadeddata($event)"
                    @waiting="onPlayerWaiting($event)"
                    @playing="onPlayerPlaying($event)"
                    @timeupdate="onPlayerTimeupdate($event)"
                    @canplay="onPlayerCanplay($event)"
                    @canplaythrough="onPlayerCanplaythrough($event)"
                    @ready="playerReadied"
                    @statechanged="playerStateChanged($event)"></video-player>
            </div>
        </div>
        <div slot="footer" class="btn dialog-footer">
            <!-- <el-button size="small" class="reelect" @click.stop="cancel()">关闭</el-button> -->
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        propsData: {
            type: Object,
            default(){
                return {
                    dialogVisible: false
                }
            }
        },
        src: {
            type: String
        },
        typeFile: {
            type: String
        }
    },
    data () {
        return {
            playerOptions: {
                height: '320',
                autoplay: false,//如果true,浏览器准备好时开始回放
                muted: false,// 默认情况下将会消除任何音频
                language: 'zh-CN',
                playbackRates: [0.7, 1.0, 1.5, 2.0],//播放速度
                sources: [{
                    src: null
                }],
                notSupportedMessage: "此视频暂无法播放，请稍后再试"
            },
            imgShow: false,
            videoShow: false
        }
    },
    methods: {
        // 2019/2/23 取消
        cancel(){
            this.propsData.dialogVisible = false;
        },
        // 视频相关事件
        onPlayerPlay(player) {
            // console.log('点击视频播放')
        },
        onPlayerPause(player) {
            // console.log('点击暂停视频停止播放')
        },
        onPlayerEnded(player) {
            // console.log('视频播放完毕')
        },
        onPlayerLoadeddata(player) {
            // console.log('视频加载中')
        },
        onPlayerWaiting(player) {
            // console.log('视频缓冲')
        },
        onPlayerPlaying(player) {
            // console.log('视频正在播放')
        },
        onPlayerTimeupdate(player) {
            // console.log('视频播放位置改变')
        },
        onPlayerCanplay(player) {
            // console.log('播放器已经准备')
        },
        onPlayerCanplaythrough(player) {
            // console.log('视频可以定点播放')
        },
        // 监听状态事件
        playerStateChanged(playerCurrentState) { //播放器当前状态
            // console.log('播放状态改变')
        },
        // 播放器已经准备好
        playerReadied(player) {
            // console.log('视频播放开始时间', player)
            player.currentTime(0) //视频开始播放时间
        },
        show(){
            this.$nextTick(_ => {
                if (this.typeFile == 'img'){
                    this.imgShow = true;
                } else if (this.typeFile == 'video') {
                    this.playerOptions.sources[0].src = this.src;
                    setTimeout(() => {
                        this.videoShow = true;
                    },500);
                }
            })
        }
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player;
        }
    }
}
</script>

<style>
    .attachment-dialog .el-dialog__body {
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
        padding: 15px;
    }
    .attachment-dialog .btn .el-button{
        padding: 9px 30px;
    }
    .attachment-dialog .btn .reelect {
        border: 1px solid #445eea;
        color: #445eea;
        margin-right: 20px;
    }
    .attachment-dialog .btn .reelect:hover{
        background: #445eea;
        color: #ffffff;
    }
</style>
