import $axios from "./service.util";


// 用户档案列表
const accountsList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/device/pageList',
    method: 'post',
    data
  })
}

// 单用户开户
const openAccount = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/device/openAccount',
    method: 'post',
    data
  })
}

// 价格模板列表
const amountTemplateList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/amountTemplate/pageList',
    method: 'post',
    data
  })
}

// 滞纳金模板列表
const znjTemplateList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/lateFee/pageList',
    method: 'post',
    data
  })
}

// 价格项目列表
const jgxmTemplateList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/amountProject/pageList',
    method: 'post',
    data
  })
}

// 价格项目列表
const savejgxmTemplate = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/amountProject/save',
    method: 'post',
    data
  })
}

// 价格项目列表
const savejgmbTemplate = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/amountTemplate/save',
    method: 'post',
    data
  })
}

// 保存滞纳金
const saveznjTemplate = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/lateFee/save',
    method: 'post',
    data
  })
}

// 保存滞纳金
const getUserDeviceInfo = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/device/getDeviceInfo',
    method: 'post',
    data
  })
}

// 充值
const recharge = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/charge/recharge',
    method: 'post',
    data
  })
}

// 充值记录
const rechargePageList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/charge/rechargePageList',
    method: 'post',
    data
  })
}

// 缴费记录
const billPayPageList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/charge/billPayPageList',
    method: 'post',
    data
  })
}

// 缴费记录
const payPageList = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/charge/payPageList',
    method: 'post',
    data
  })
}

// 获取水司下所有价格模板
const getAllJGTemplate = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/amountTemplate/allList',
    method: 'post',
    data
  })
}

// 获取水司下所有滞纳金模板
const getAllZNJTemplate = (data) => {
  return $axios({
    url: process.env.VUE_APP_BASE_API + '/lateFee/allList',
    method: 'post',
    data
  })
}

export default {
  accountsList,
  openAccount,
  amountTemplateList,
  znjTemplateList,
  jgxmTemplateList,
  savejgxmTemplate,
  savejgmbTemplate,
  saveznjTemplate,
  getUserDeviceInfo,
  recharge,
  rechargePageList,
  billPayPageList,
  payPageList,
  getAllJGTemplate,
  getAllZNJTemplate
}
