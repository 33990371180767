<template>
  <div class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive v-if="$route.meta.keepAlive">
          <router-view :key="key"></router-view>
      </keep-alive>
      <router-view :key="key" v-else/>
    </transition>
  </div>
</template>

<script>
  export default {
    computed: {
      key() {
        return this.$route.fullPath
      }
    }
  }
</script>
<style>
  .app-main {
    height: 100%;
    background-color: #ebeff6;
  }
</style>

