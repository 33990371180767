<template>
    <div v-if="!item.hidden && item.children">
        <!--
            onlyOneChild 变量 当路由配置仅有子路由时 onlyOneChild 将赋值 仅有的一个子路由
            !onlyOneChild.children 所有不能再有 三级路由出现
            onlyOneChild.noShowingChildren 假如无子路由显示父路由时 展示字段为手动添加字段
         -->
        <template v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
            <el-menu-item :index="resolvePath(onlyOneChild.path)">
                <span v-if="onlyOneChild.meta" slot="title">
                    <!-- <i :class="onlyOneChild.meta.icon"></i> -->
                    <img class="icon" :src="onlyOneChild.meta.icon" alt="">
                    {{ onlyOneChild.meta.title }}
                </span>
            </el-menu-item>
        </template>
        <template v-else>
            <el-submenu :index="resolvePath(item.path)">
                <template slot="title">
                    <span v-if="item.meta">
                        <!-- <i :class="item.meta.icon"></i> -->
                        <img class="icon" :src="item.meta.icon" alt="">
                        {{ item.meta.title }}
                    </span>
                </template>
                <div class="elmenu-item"  v-for="child in item.children" :key="child.path">
                    <template v-if="!child.hidden">
                        <!-- 三级路由 -->
                        <sidebar-item v-if="child.children && child.children.length > 0"
                            :item="child"
                            :key="child.path"
                            :base-path="child.path"
                            ></sidebar-item>
                        <!-- 二级路由 -->
                        <template v-else>
                            <el-menu-item class="elmenu-item" :index="resolvePath(child.path)">
                                <span v-if="child.meta" slot="title">
                                    <!-- <i :class="child.meta.icon"></i> -->
                                    <img class="icon" :src="childicon" alt="">
                                    {{ child.meta.title }}
                                </span>
                            </el-menu-item>
                        </template>
                    </template>
                </div>
            </el-submenu>
        </template>
    </div>
</template>

<script>
import path from 'path';
// import SidebarItem from "./SidebarItem";
export default {
    name: 'SidebarItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        basePath: {// 默认path
            type: String,
            default: ''
        }
    },
    data () {
        return {
            onlyOneChild: null, // 只有一个子路由
            childicon: require('@/assets/icon/menu/child.png')
        }
    },
    methods: {
        // 如果只有一个子路由默认为 一级路由
        hasOneShowingChild(children, parent){
            /**
             * showingChildren 再菜单栏展示的路由
             */
            const showingChildren = children.filter(item => {
                if (item.hidden){ // 判断是否展示
                    return false;
                } else {
                    this.onlyOneChild = item;
                    return true;
                }
            });
            /**
             * 只有一个子路由时,默认情况下子路由会直接显示在菜单组件的一级
             */
            if (showingChildren.length === 1){
                return true;
            }
            /**
             * 如果没有子路由,则显示父路由器
             */
            if (showingChildren.length === 0){
                this.onlyOneChild = { ...parent, noShowingChildren: true };
                return true;
            }
            return false;
        },
        // 因为子路由要拥有父级路由默认path 需要通过函数改变
        resolvePath(routePath){
            if (this.isExternalLink(routePath)){
                return routePath
            }
            return path.resolve(this.basePath, routePath);
        },
        isExternalLink(routePath){
            return this.isExternal(routePath);
        },
        isExternal(path) {
            return /^(https?:|mailto:|tel:)/.test(path);
        }
    }
}
</script>
<style>
  .icon{
      width: 16px;
      height: 16px;
      margin-bottom: 4px;
  }
</style>

