import $axios from "@/service/service.util.js";

//管理员登录
const adminLogin = (data) => {
  return $axios({
    url: 'http://8.129.106.36:8765/NB/Login',
    method: 'post',
    data
  })
}
export default {
  adminLogin
}
