<template>
    <div class="attachment-preview">
        <span class="span" @click="attachmentPreview(data)">
            <slot name="Text"></slot>
        </span>
        <attachment-dialog ref="attachment" :propsData="attachmentDialogProps" :src="src" :typeFile="typeFile"></attachment-dialog>
    </div>
</template>

<script>
import attachmentDialog from "./components/attachmentDialog";
export default {
    name: 'GlobalAttachmentPreview',
    components: {
        attachmentDialog
    },
    props: {
        data: Object,
        onlyFlow: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            attachmentDialogProps: {
                dialogVisible: false
            },
            src: "",// 附件src
            attachmentType: ['image/png', 'image/jpeg', 'image/jpg'],// 可预览的附件
            typeFile: ''// 判断是否视频/图片
        }
    },
    methods: {
        attachmentPreview(data){
            console.log(data);
            console.log(this.onlyFlow);
            if (data && data.fileId){
                if (this.check(data)){
                    let reqData = {
                        previewId: data.fileId
                    }
                    const loading = this.$loading({
                        lock: true,
                        text: '文件加载中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(255, 255, 255, 0.7)'
                    });
                    this.$api.attachmentPreview(reqData).then(res => {
                        this.src = window.URL.createObjectURL(res);
                        if (data.fileType == 'image/png' || data.fileType == 'image/jpeg' || data.fileType == 'image/jpg'){
                            this.typeFile = 'img';
                        }
                        // else if (data.fileType == 'pdf') {
                        //     // 1. pdf转 图片
                        //     // 2. 赋值 this.src
                        //     // 3. this.typeFile = 'img';
                        // }
                        this.$refs.attachment.show();
                        this.attachmentDialogProps.dialogVisible = true;
                        loading.close();
                    });
                } else {
                    this.$message.warning("附件仅支持：.png、.jpeg、.jpg");
                }
            } else if (data && this.onlyFlow) {
                this.src = data.src;
                this.typeFile = 'img';
                this.$refs.attachment.show();
                this.attachmentDialogProps.dialogVisible = true;
            }
        },
        // 附件预览验证
        check(data){
            let clock = false;
            clock = this.attachmentType.some((item) => {
                return item === data.fileType;
            })
            return clock;
        }
    }
}
</script>

<style>
    .attachment-preview {
        display: inline-block;
    }
    .attachment-preview > .span {
        margin-right: 0;
        cursor: pointer;
    }
</style>
