/**
 * 全局自定义指令
 */
import Vue from "vue";


// 示例代码：

const urgentColor = Vue.directive('urgentColor', {
    inserted(el, binding) {
        console.dir(el);
        console.dir(el.title);
    }
})

export default {
    urgentColor
}
