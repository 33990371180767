import server from "./server";// 主 server
import loginServer from "@/views/Login/server"
import kcServer from "@/views/SBGL/Device/server"
// 合并所有serverApi
const serverApi = { ...server, ...loginServer, ...kcServer };

const install = Vue => {
    if (install.installed) {
        return
    };
    install.installed = true;

    Object.defineProperties(Vue.prototype, {
        $api: {
            get() {
                return serverApi
            }
        }
    })
}

export default install;
