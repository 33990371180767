import GlobalUpload from "./upload/index.js";// 上传组件
import GlobalDownload from "./download/index.js";// 下载组件
import GlobalAttachmentPreview from "./attachment/index.js";// 附件在线预览
import GlobalEditor from "./editor/index.js";// 富文本编辑器
const components = [
    GlobalUpload,
    GlobalDownload,
    GlobalAttachmentPreview,
    GlobalEditor
]

const install = (Vue) => {
    components.forEach(component => {
        Vue.component(component.name, component);
    })
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}

export default {
    install,
    GlobalUpload,
    GlobalDownload,
    GlobalAttachmentPreview,
    GlobalEditor
}