import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import "./styles/reset.css";
import 'element-ui/lib/theme-chalk/index.css';
import "./styles/global.css";
// import './assets/icon/iconfont.css'; // 图标字样式
import router from './router/index';
import store from './store/index';
import $service from "./service/index";
import $directive from './directive/index';
import $filters from './filters/index';
import $Fn from './utils/public';
import dictionaries from "./dictionary/index";
import Golbal from "./components/index.js";
Vue.config.productionTip = false;// 设置为 false 以阻止 vue 在启动时生成生产提示。
Vue.use($service);// 使用 axios 封装服务
Vue.use(ElementUI);// 使用 ElementUI
Vue.use($directive);// 使用全局自定义指令
Vue.use($filters);// 使用全局自定义管道(过滤器)
Vue.use(Golbal);// 使用全局公共组件
Vue.prototype.$Fn = $Fn;// 全局公共函数
Vue.prototype.$dictionaries = dictionaries;// 全局字典表
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
